import InputField from "components/Inputs/InputField";
import Header from "components/Navbar";
import YourOrder from "components/Sections/YourOrder";
import { Text } from "components/typography/Typography";
import { Billing_Detail, Shipping_detail } from "data/billingfor";
import { useState } from "react";
import { font_family } from "utils/fontFamily";
import { validateEmail } from "utils/methods";

const FormComponent = () => {
  const [showLoginSection, setShowLoginSection] = useState(false);
  const [showCouponSection, setshowCouponSection] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [billingHandler, setBillingHandler] = useState({
    isErrorEnabled: false,
    ErrorMessage: null,
    Error_Check: [],
    isShipmentAddressChange: false,
  });
  const handleLoginClick = (event) => {
    event.preventDefault();
    setShowLoginSection(!showLoginSection);
  };

  const handleLoginCoupon = (event) => {
    event.preventDefault();
    setshowCouponSection(!showCouponSection);
  };

  const onChangeShipmentAddress = () => {
    setBillingHandler({
      ...billingHandler,
      isShipmentAddressChange: !billingHandler?.isShipmentAddressChange,
    });
  };

  const onChangeInputField = (val, key) => {
   
    setOrderDetail({ ...orderDetail, [key]: val.target.value });
    setBillingHandler((prevState) => ({
      ...prevState,
      Error_Check: prevState?.Error_Check?.map((item) =>
        item.field_name === key
          ? {
              field_name: key,
              required:
                item?.field_name == "email_address"
                  ? !validateEmail(orderDetail?.email_address)
                  : false,
            }
          : item
      ),
    }));
  };
  const onClickedButton = () => {
    const temp_all_detail = billingHandler?.isShipmentAddressChange
      ? [...Billing_Detail, ...Shipping_detail]
      : [...Billing_Detail];
    const check_empty_key = temp_all_detail.map((item) => {
      if (
        (item?.required && orderDetail[item?.field_name] == "") ||
        !Object?.hasOwn(orderDetail, item?.field_name)
      ) {
        return {
          field_name: item?.field_name,
          required: item?.required,
        };
      }
      return false;
    });


    if (
      check_empty_key.some((item) => item?.field_name) ||
      !validateEmail(orderDetail?.email_address)
    ) {
      setBillingHandler({
        ...billingHandler,
        Error_Check: check_empty_key,
        ErrorMessage: "Please Enter Reqired Field",
      });
    } else {
      console.log("hello successfully done");
    }
  };
  return (
    <div>
      <Header />
{console.log(billingHandler?.Error_Check)}
      <div className="section checkout-sec">
        <div className="container checkout-container">
          <div className="row Click-here-Row">
            <div className="col-md-12">
              <h5>
                Returning customer?
                <a href="#" onClick={handleLoginClick}>
                  Click here to login
                </a>
              </h5>
            </div>
          </div>
          <br />
          <div className="row Click-here-Row1">
            <div className="col-md-12">
              <h5>
                Have a coupon?
                <a href="#" onClick={handleLoginCoupon}>
                  Click here to enter your code
                </a>
              </h5>
            </div>
          </div>
          {showCouponSection && (
            <div className="Dropdown-sec">
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    placeholder={"Coupon Code"}
                    fieldName={
                      "If you have a coupon code, please apply it below."
                    }
                  />
                </div>
                <div className="col-md-6">
                  <div style={{ paddingTop: "34px" }}>
                    <button type="submit">Apply coupon</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <div className="row">
            <div className="row">
              <div className="col-md-6 d-lfex align-items-center">
                <h4>Billing details</h4>
              </div>

              <div className="col-md-6">
                <div className="d-flex align-items-center mx-5">
                  <InputField
                    type={"checkbox"}
                    onChange={onChangeShipmentAddress}
                  />
                  <Text
                    text={"Want to Ship on Different Address"}
                    fontFamily={font_family?.textEdo}
                    className={'mb-0'}
                   
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row d-flex flex-wrap">
                {Billing_Detail?.map((item, index) => (
                  <div
                    className={index > 1 ? "col-md-12" : "col-md-6"}
                    key={index}
                  >
                    <InputField
                      value={orderDetail[item?.field_name]}
                      errorText={billingHandler?.ErrorMessage}
                      margin={"10px 0px 10px 0px"}
                      fieldName={item?.placeholder}
                      placeholder={item?.placeholder}
                      isError={billingHandler?.Error_Check[index]?.required}
                      onChange={(val) =>
                        onChangeInputField(val, item?.field_name)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-6">
              <div className="row d-flex flex-wrap">
                {billingHandler?.isShipmentAddressChange &&
                  Shipping_detail?.map((item, index) => (
                    <div
                      className={index > 1 ? "col-md-12" : "col-md-6"}
                      key={index}
                    >
                      <InputField
                        value={orderDetail[item?.field_name]}
                        errorText={billingHandler?.ErrorMessage}
                        fieldName={item?.placeholder}
                        margin={"10px 0px 10px 0px"}
                        placeholder={item?.placeholder}
                        isError={billingHandler?.Error_Check[index]?.required}
                        onChange={(val) =>
                          onChangeInputField(val, item?.field_name)
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
            <YourOrder />
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "end" }}>
                <button type="submit" onClick={onClickedButton}>
                  Place order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <GoogleReviews/> */}
    </div>
  );
};
export default FormComponent;
