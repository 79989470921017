import { CategoryCardData } from "data/category";
import React from "react";
import "components/Sections/section.css";
import { CardwithText } from "components/Cards/Card";
import { navigateTo } from "utils/methods";
import { useNavigate } from "react-router-dom";
const CategorySection = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="row my-4">
        {CategoryCardData?.map((item, index) => (
          <CardwithText
            key={index}
            CardTextClassName={"card_title_name mb-0"}
            CardImageClassName={"mb-3"}
            CardTitle={item?.title}
            CardImage={item?.Card_IMG}
            onClick={() => {navigateTo(`/products/${item?.title}`,'',navigate)}}
          />
        ))}
      </div>
    </div>
  );
};

export default CategorySection;
