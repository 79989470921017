import { Image } from "components/Images/Image";
import { InstagramProductData } from "data/category";
import React from "react";

const InstagramSection = ({}) => {
  return (
    <React.Fragment>
      <h1 className="text-center my-4">INSTAGRAM</h1>
      <div className="container-fluid px-0">
        <div className="row">
          {InstagramProductData?.map((item,index) => (
            <div className="col-md-2 px-0" key={index}>
              <Image src={item} />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstagramSection;
