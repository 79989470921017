import React from "react";

const YourOrder = () => {
  return (
    <div className="table-sec">
      <div className="table">
        <h2>Your order</h2>
        <table>
          <tbody>
            <tr>
              <th>Product</th>
              <th>Subtotal</th>
            </tr>
            <tr>
              <th>White Musk Laundry Essence - 100ml × 1</th>
              <th>£5</th>
            </tr>
            <tr>
              <th>Subtotal</th>
              <th>£5</th>
            </tr>
            <tr>
              <th>Shipping</th>
              <th>Charges: £5.00</th>
            </tr>
            <tr>
              <th>Total</th>
              <th>£10.00</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YourOrder;
