import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Icon, Image } from "components/Images/Image";
import { Text } from "components/typography/Typography";
import "components/Modals/modal.css";
import { ThemeColor } from "utils/themeColor";
import { useDataFlow } from "utils/customHooks";
import { CartDataContext } from "statemanagement/Context";
import { font_family } from "utils/fontFamily";
import Lottie from "react-lottie";
import EmptyCart from "assets/lottiefiles/Animation - 1717143245735.json";
import Inputwithtitle from "components/Inputs/InputwithIcons";
import ButtonWithTitle from "components/Buttons/ButtonWithTitle";
import { navigateTo } from "utils/methods";
import { useNavigate } from "react-router-dom";
const ViewCartModal = ({ isOpenModal, onClose }) => {
  const {cartData, setCartData} = useContext(CartDataContext);
  const navigate = useNavigate();
  const [cartModalView, setCartModalView] = useState({
    coupanCode: "",
    TotalPrice: null,
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EmptyCart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onNavigate=()=>{
    navigateTo('/checkout','',navigate)
  }

  useEffect(() => {
   
      const SubTotalPriceForCart = [...cartData];
      const Total_Price = SubTotalPriceForCart.reduce(
        (accumulator, currentObject) => {
          return (
            accumulator +
            Number(currentObject.productQuantity * currentObject.productPrice)
          );
        },
        5
      );
      setCartModalView({
        ...cartModalView,
        TotalPrice: parseFloat(Total_Price).toFixed(2),
      });
    
  }, [cartData]);

  const OnProductClear = (item) => {
    const findExsisitItemToClear = cartData?.findIndex(
      (cart) => cart.id == item.id
    );
    const delted_Items = [...cartData];
    delted_Items.splice(findExsisitItemToClear, 1);
    setCartData(delted_Items);
  };

  const onCartUpdate = (val, index) => {
    const cartUpdate = [...cartData];
    cartUpdate[index].productQuantity = Number(val) >= 1 && Number(val);
    setCartData(cartUpdate);
    setCartModalView({
      ...cartModalView,
      TotalPrice: parseFloat(
        cartUpdate[index].productPrice * cartUpdate[index].productQuantity + 5
      ).toFixed(2),
    });
  };

  return (
    <Modal show={isOpenModal} style={{ position: "fixed", top: "5%" }}>
      <div className="container-fluid px-3 pt-2">
        <div className="d-flex align-items-center w-100">
          <Icon
            onClick={onClose}
            className={"bi bi-x"}
            color={ThemeColor?.darkBlue}
            fontSize={30}
            cursor={"pointer"}
          />
          <Text
            className={
              "mb-0 text-center d-flex justify-content-center w-100 margn-right"
            }
            text={"Cart Detail"}
            fontFamily={font_family?.textEdo}
            fontSize={20}
            color={ThemeColor.darkBlue}
          />
        </div>
        <div className="row border-color">
          <div className="col-md-6">
            <Text className={"mb-0 font-header"} text={"Product"} />
          </div>

          <div className="col-md-2">
            <Text className={"mb-0 font-header"} text={"Price"} />
          </div>

          <div className="col-md-2">
            <Text className={"mb-0 font-header"} text={"Quantity"} />
          </div>

          <div className="col-md-2">
            <Text className={"mb-0 font-header"} text={"SubTotal"} />
          </div>
        </div>
        {cartData.length !== 0 ? (
          cartData.map((item, index) => (
            <div className="row border-listing" key={index}>
              <div className="col-md-6 d-flex align-items-center">
                <Icon
                  onClick={() => OnProductClear(item)}
                  className={"bi bi-x"}
                  color={ThemeColor.darkBlue}
                  fontSize={20}
                  cursor={"pointer"}
                />
                <Image
                  className={"mx-5"}
                  src={item?.img}
                  height={"30px"}
                  width={"30px"}
                />
                <Text
                  className={"mb-0 font-header"}
                  text={item?.title}
                  color={ThemeColor.darkBlue}
                />
              </div>

              <div className="col-md-2">
                <Text
                  className={"mb-0 font-header"}
                  text={`£${item?.productPrice}`}
                  color={ThemeColor.camelColor}
                />
              </div>

              <div className="col-md-2">
                <Inputwithtitle
                  onChange={(val) => onCartUpdate(val, index)}
                  type={"number"}
                  value={item?.productQuantity}
                  containerClass={"input_container"}
                />
              </div>

              <div className="col-md-2">
                <Text
                  className={"mb-0 font-header"}
                  color={ThemeColor.camelColor}
                  text={`£${parseFloat(
                    item?.productPrice * item?.productQuantity
                  ).toFixed(2)}`}
                />
              </div>
            </div>
          ))
        ) : (
          <Lottie options={defaultOptions} height={150} width={150} />
        )}

        <div className="d-flex">
          <Inputwithtitle
            value={cartModalView?.coupanCode}
            onChange={(val) => {}}
            inputClass={"coupon_input"}
            placeholder={"Coupon Code"}
          />
          <ButtonWithTitle className={"apply-coupon"} title={"Apply Coupon"} />
        </div>

        <div className="w-100 px-5 my-3">
          <Text
            text={"Cart totals"}
            fontFamily={font_family?.textEdo}
            fontSize={20}
            color={ThemeColor?.darkBlue}
          />

          <div className="d-flex justify-content-between border-color px-2">
            <Text
              fontweight={"500"}
              color={ThemeColor?.darkBlue}
              text={"SubTotal"}
            />
            <Text
              text={`£${cartModalView?.TotalPrice - 5}`}
              fontweight={"500"}
              color={ThemeColor?.darkBlue}
            />
          </div>
          <div className="d-flex justify-content-between border-color px-2">
            <Text
              text={"Shipping"}
              fontweight={"500"}
              color={ThemeColor?.darkBlue}
            />
            <div>
              <Text
                text={"Charges: £5.00"}
                fontweight={"500"}
                textAlign={"right"}
                color={ThemeColor?.darkBlue}
              />
              <br />
              <Text
                fontFamily={font_family?.textEdo}
                color={ThemeColor?.darkBlue}
                text={"Shipping options will be updated during checkout."}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between border-color px-2">
            <Text
              text={"SubTotal"}
              fontweight={"500"}
              color={ThemeColor?.darkBlue}
            />
            <Text
              text={`£${cartData?.length !==0 ? cartModalView?.TotalPrice : 0}`}
              fontweight={"500"}
              color={ThemeColor?.darkBlue}
            />
          </div>
        </div>

        <div>
          <ButtonWithTitle
          onClick={onNavigate}
            className={"apply-coupon w-90 mx-5"}
            title={"Proceed to Checkout"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ViewCartModal;
