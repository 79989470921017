import { Icon } from "components/Images/Image";
import React from "react";

const Inputwithtitle = ({
  onChange,
  value,
  placeholder,
  containerClass,
  IconClass,
  iconSize,
  disabled,
  InputLeftIcon,
  InputRightIcon,
  inputClass,
  type
}) => {
  return (
    <div className={containerClass}>
      {InputLeftIcon && (
        <Icon className={IconClass} fontSize={iconSize} />
      )}
      <input
      type={type}
        disabled={disabled}
        onChange={(val) => onChange(val.target.value)}
        value={value}
        placeholder={placeholder}
        className={inputClass}
      />
     
      {InputRightIcon && (
        <Icon className={IconClass} fontSize={iconSize} />
      )}
    </div>
  );
};

export default Inputwithtitle;
