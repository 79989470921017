import React from "react";
import { Text } from "components/typography/Typography";

export const MultipleItemsDropdown = ({
  DropdownContainer,
  className,
  MultiItemsInput,
  ItemsStyle,
  textClass,
  itemKey,
  textWrap,
}) => {
  return (
    <div className={className}>
      <div className={DropdownContainer}>
        {MultiItemsInput?.map((item, index) => (
          <div className={ItemsStyle} key={index}>
            <Text text={item[itemKey]} className={textClass} textWrap={textWrap}/>
          </div>
        ))}
      </div>
    </div>
  );
};
