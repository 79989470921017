import { Image } from "components/Images/Image";
import Header from "components/Navbar";
import CategorySection from "components/Sections/CategorySection";
import ContactSection from "components/Sections/ContactSection";
import InstagramSection from "components/Sections/InstagramSection";
import MapView from "components/Sections/MapView";
import OurEssenceSection from "components/Sections/OurEssenceSection";
import AutoSliderSwiper from "components/Swipers/AutoSlideSwiper";
import { Images } from "constants/image";
import { SlidersImage } from "data/sliders";
import { CategoryListing, ProductListing } from "networking/networking";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProductCategory } from "statemanagement/redux/slices/categoryListing";
import { setProducts } from "statemanagement/redux/slices/productListing";

const Home = () => {
  const [dashbaordHandler, setDashboardHandler] = useState({
    ProductListing: [],
    CategoryListing: [],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    Promise.all([ProductListing(), CategoryListing()])
      .then((resp) => {
        if (resp[0]?.data?.status == 200 && resp[1]?.data?.status == 200) {
          dispatch(setProducts(resp[0]?.data?.data));
          dispatch(setProductCategory(resp[1]?.data?.data));
          setDashboardHandler((prevValues) => ({
            ...prevValues,
            ProductListing: resp[0]?.data?.data?.data,
            CategoryListing: resp[1]?.data?.data,
          }));
        }
      })
      .catch((error) => {
        throw error;
      });
  }, []);
  return (
    <div>
      <Header />
      <AutoSliderSwiper
        Data={SlidersImage}
        autoplay={true}
        autoplayDelay={2500}
        className={"mySwiper"}
        slidesPerView={1}
      />
      <CategorySection />
      <ContactSection />
      <OurEssenceSection
        textAlign={"center"}
        sectionHeading={"Our Essence"}
        path={"products/product-detail"}
        OurEssenceProduct={dashbaordHandler?.ProductListing?.filter(
          (item) => item?.subcat_name == "LAUNDRY ESSENCE"
        )}
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6">
            <Image src={Images?.GiftImage} />
          </div>
          <div className="col-md-6">
            <Image src={Images?.KidsImage} />
          </div>
        </div>
      </div>
      <MapView />
      <div className="container-fluid">
        <InstagramSection />
      </div>
    </div>
  );
};
export default Home;
