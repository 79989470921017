import { MultipleItemsDropdown } from "components/Dropdowns/Dropdowns";
import { Icon, Image } from "components/Images/Image";
import ViewCartModal from "components/Modals/ViewCartModal";
import "components/Navbar/index.css";
import { Images } from "constants/image";
import { AmrasicoProducts } from "data/category";
import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import { CartDataContext } from "statemanagement/Context";
import { useDataFlow } from "utils/customHooks";
import { ThemeColor } from "utils/themeColor";
import Offcanvas from "react-bootstrap/Offcanvas";
import Inputwithtitle from "components/Inputs/InputwithIcons";
import { Text } from "components/typography/Typography";
import { useSelector } from "react-redux";
const Header = () => {
  const { cartData, themeColor, setThemeColor } = useContext(CartDataContext);
  const [isOpenCartModal, setIsOpenCartModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [expanded, setExpanded] = useState(false);
  const closeNavbar = () => setExpanded(false);
  const productCategories = useSelector((state) => state.productCategories);
  const onThemeColorChange = () => {
    setThemeColor(!themeColor);
  };
  return (
    <React.Fragment>
      <Offcanvas show={show} onHide={handleClose} placement="top">
        <Offcanvas.Body>
          <Inputwithtitle
            placeholder={"Search"}
            inputClass={"w-100 searchBarClass"}
            onChange={(val) => {}}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <Navbar expand="lg mt-2" className="positio-relative">
        <Navbar.Brand>
          <Link to={"/"}>
            <Image src={Images?.AppLogo} className={"app-logo"} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse className="nav-link-container" id="basic-navbar-nav">
          <Nav onClick={closeNavbar}>
            <NavLink to="/who-we-are" className="mx-2 nav-link">
              WHO WE ARE
            </NavLink>
            <NavLink className="mx-2 nav-link" to={`/products/${productCategories[2]?.name.replace(' ','_').toLowerCase()}`}>
              AMARASICO PRODUCTS
              <Icon
                className={"bi bi-chevron-down"}
                color={ThemeColor?.plainTextColor}
                fontSize={"12px"}
                margin={"0px 4px"}
              />
              <MultipleItemsDropdown
                ItemsStyle={"mb-0 my-3 cartClass"}
                DropdownContainer={"DropdownContainer"}
                MultiItemsInput={productCategories[2]?.sub_category}
                itemKey={"title"}
                textClass={"mb-0"}
                textWrap={'nowrap'}
/>
            </NavLink>
            <NavLink to={`/products/${productCategories[1]?.name.replace(' ','_').toLowerCase()}`} className="mx-2 nav-link">
              BIO PRODUCTS
              <Icon
                className={"bi bi-chevron-down"}
                color={ThemeColor?.plainTextColor}
                fontSize={"12px"}
                margin={"0px 4px"}
              />
              <MultipleItemsDropdown
                ItemsStyle={"mb-0 my-3 cartClass"}
                DropdownContainer={"DropdownContainer"}
                MultiItemsInput={productCategories[1]?.sub_category}
                itemKey={"title"}
                textClass={"mb-0"}
                textWrap={'wrap'}
              />
            </NavLink>
            <NavLink to="/products/Fragrances" className="mx-2 nav-link">
              Sanitizing
            </NavLink>
            <NavLink to="/quality" className="mx-2 nav-link">
              Quality
            </NavLink>
          </Nav>
        </Navbar.Collapse>

        <div className="d-flex cart-account">
          <NavLink className="mx-2 nav-link">
            <Icon
              className={"bi bi-person"}
              fill={true}
              color={ThemeColor?.plainTextColor}
            />
            Account
          </NavLink>
          <Icon
            onClick={onThemeColorChange}
            className={themeColor ? "bi bi-brightness-high" : "bi bi-moon"}
            fill={true}
            color={ThemeColor?.plainTextColor}
          />
          <div
            className="mx-3 d-flex align-items-center cursor-pointer"
            onClick={() => setIsOpenCartModal(true)}
          >
            <Icon
              className={"bi bi-bag"}
              fill={true}
              color={ThemeColor?.plainTextColor}
            />
            <Text text={`${cartData.length} items`} className={"mb-0"} />
          </div>
          <div className="search_Icon">
            <Icon
              className={"bi bi-search"}
              color={ThemeColor?.plainTextColor}
              onClick={handleShow}
            />
          </div>
        </div>
        <ViewCartModal
          isOpenModal={isOpenCartModal}
          onClose={() => setIsOpenCartModal(false)}
        />
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
