import { createSlice } from "@reduxjs/toolkit";
const ProductListing = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    setProducts: (state, action) => {
      return action.payload
    },
  },
});
export const { setProducts } = ProductListing.actions;
export default ProductListing.reducer;
