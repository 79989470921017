import React from "react";
import { font_family } from "utils/fontFamily";
import "components/Swipers/swiper.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Rating_Stars from "pages/ProductDetails/Rating";
import { Text } from "components/typography/Typography";
import { Image } from "components/Images/Image";
import ButtonWithIcon from "components/Buttons/ButtonWithIcon";
const ReviewSectionSlider = ({
  Data,
  autoplayDelay,
  autoplay,
  className,
  slidesPerView,
}) => {
  return (
    <div>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        breakpoints={{
          768: {
            slidesPerView: 1,
          },

          992: {
            slidesPerView: 2,
          },

          1200: {
            slidesPerView: 3,
          },
        }}
        autoplay={{
          delay: 2000,
          autoplay: true,
          disableOnInteraction: false,
        }}
        navigation={false}
        modules={[Navigation, Autoplay]}
        className={"swiper-height"}
      >
        <SwiperSlide className="swiper-height">
          <div className="bg-white box-shadow">
            <div className="d-flex">
              <Image
                src={
                  "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
                }
                objectFit={"cover"}
                className={"border_radius_profile"}
              />
              <Text
                text={"Amarasico di Magli Robert"}
                className={"mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
            </div>

            <div className="px-4">
              <div className="d-flex">
                <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
              </div>
              <div>
                <Text
                  text={
                    "Looking for a perfume for the washing machine I found them. I take two small 100ml fragrances for trial and we like them so much, so I"
                  }
                  className={"mb-1"}
                  fontFamily={font_family?.textKarla}
                  fontweight={"500"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-height">
          <div className="bg-white box-shadow">
            <div className="d-flex">
              <Image
                src={
                  "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
                }
                objectFit={"cover"}
                className={"border_radius_profile"}
              />
              <Text
                text={"Amarasico di Magli Robert"}
                className={"mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
            </div>

            <div className="px-4">
              <div className="d-flex">
                <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
              </div>
              <div>
                <Text
                  text={
                    "Looking for a perfume for the washing machine I found them. I take two small 100ml fragrances for trial and we like them so much, so I"
                  }
                  className={"mb-1"}
                  fontFamily={font_family?.textKarla}
                  fontweight={"500"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-height">
          <div className="bg-white box-shadow">
            <div className="d-flex">
              <Image
                src={
                  "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
                }
                objectFit={"cover"}
                className={"border_radius_profile"}
              />
              <Text
                text={"Amarasico di Magli Robert"}
                className={"mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
            </div>

            <div className="px-4">
              <div className="d-flex">
                <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
              </div>
              <div>
                <Text
                  text={
                    "Looking for a perfume for the washing machine I found them. I take two small 100ml fragrances for trial and we like them so much, so I"
                  }
                  className={"mb-1"}
                  fontFamily={font_family?.textKarla}
                  fontweight={"500"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-height">
          <div className="bg-white box-shadow">
            <div className="d-flex">
              <Image
                src={
                  "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
                }
                objectFit={"cover"}
                className={"border_radius_profile"}
              />
              <Text
                text={"Amarasico di Magli Robert"}
                className={"mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
            </div>

            <div className="px-4">
              <div className="d-flex">
                <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
              </div>
              <div>
                <Text
                  text={
                    "Looking for a perfume for the washing machine I found them. I take two small 100ml fragrances for trial and we like them so much, so I"
                  }
                  className={"mb-1"}
                  fontFamily={font_family?.textKarla}
                  fontweight={"500"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-height">
          <div className="bg-white box-shadow">
            <div className="d-flex">
              <Image
                src={
                  "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
                }
                objectFit={"cover"}
                className={"border_radius_profile"}
              />
              <Text
                text={"Amarasico di Magli Robert"}
                className={"mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
            </div>

            <div className="px-4">
              <div className="d-flex">
                <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
              </div>
              <div>
                <Text
                  text={
                    "Looking for a perfume for the washing machine I found them. I take two small 100ml fragrances for trial and we like them so much, so I"
                  }
                  className={"mb-1"}
                  fontFamily={font_family?.textKarla}
                  fontweight={"500"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ReviewSectionSlider;
