export const AdditionInfo = [
  {
    buttonText: "Description",
    heading: "Perfume your laundry with our White Musk fragrance",
    description:
      "A radiant and intense floral elixir of Orchid combined with the elegant notes of Passiflora and Buttercup illuminated by sumptuous Fruity Musks.Wraps the garments with a radiant and sophisticated scent ...Olfactory Pyramid fragrance Top: Lemon, Orchid and Green Leaves Heart: Rose, Passiflora, Ranuncolo, Rubignosa and Magnolia Base: Sandalwood, White Musk, Vanilla and Fruity Blends Dosage:1ml for each kg of laundry to be poured directly into the softener compartment The essence for laundry with the White Musk fragrance is a product designed for your laundry, suitable for the whole family and also suitable for children. Completely Made in Italy",
    dosage: "Oil,Fragrance && Olive Oil",
  },

  {
    buttonText: "Addtional Information",
    heading: "Additional information",
    description:
      "With Indeed, you can search millions of jobs online to find the next step in your career. With tools for job search, CVs, company reviews and more, ...",
    choose_attribute: "100ml/500ml",
    taste:'Vanila',
  },
  {
    buttonText: "Reviews",
    heading: "Reviews",
  },
];



export const keysNotInclude=['buttonText','heading']

