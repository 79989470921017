import React from "react";
import { Link } from "react-router-dom";
import weblogo from "assets/images/THS-Plus-Logo-Dark-2-1-1.png";
import Am from "assets/images/am.png";
import "./Footer.css";
import ReviewSection from "components/Sections/ReviewSection";
//
export default function Footer() {
  const handleRedirectToPdf = () => {
    window.location.href = "#"; // Replace with your PDF file URL
  };
  return (
    <div>
       <div className="container">
        <ReviewSection/>
        </div>
      <div
        className="footer">
        <div className="container">
      
          <div className="row">
            <div className="col-md-4 footer-logo ">
              <a to="/">
                <img
                  src={weblogo}
                  style={{
                    padding: "0px 0px 20px 0px",
                  }}
                />
              </a>
              <div className="logop">
              <p style={{ 
                marginBottom:"9px"
               }}>
                Company registration number: 12689259
              </p>
              <p>
                
                THS+ Ltd. are unique importers and exclusive distributors for
                Amarasico products and represent the partnership’s interests in
                the UK.
              </p>
              </div>
            </div>
            <div className="col-md-4">
              <h4>Payment Methods</h4>
              <div className="amimage">
              <img
                src={Am}
                style={{
                  width: "182px",
                }}
              />
              </div>
              
              <h4>Terms and Conditions</h4>
              <div className="credits ">
                <p>
                  <a
                    onClick={handleRedirectToPdf}
                    style={{ 
                      cursor: "pointer", 
                      textDecoration: "underline" }}
                  >
                    Click here
                  </a>{" "}
                  &nbsp; to see our full terms and conditions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <h4>Contact us</h4>
             
              
              <p class="bi bi-geo-alt-fill">&nbsp;  Sadler Bridge Studios, Bold Lane Derby, DE1 3NT</p>
              
              <hr/>
              <p><a  className="bi bi-envelope" href="mailto:amarasico@thsplus.co.uk">  &nbsp; 
                amarasico@thsplus.co.uk
              </a></p>
              <hr/>
              <p><a className="bi bi-telephone" href="tel:01332323989" > &nbsp; 
                01332 323 989
              </a></p>
              <hr/>
            </div>
          </div>
        </div>
      </div>
      <div className=" bottomBar">
        <div className="container">
          <div className="row justify-content" style={{ paddingTop: "10px" }}>
            <div className="col-md-12 text-center">
              <p className="copyright">
                &copy; Copyrights Amarasico. © 2022 All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
