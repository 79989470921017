export const Billing_Detail = [
  {
    field_name: "first_name",
    value: "",
    placeholder: "First Name",
    required: true,
    type: "input",
  },
  {
    field_name: "last_name",
    value: "",
    placeholder: "Last Name",
    required: true,
    type: "input",
  },
  {
    field_name: "company_name",
    value: "",
    placeholder: "Company Name",
    required: false,
    type: "input",
  },
  {
    field_name: "country_region",
    value: "",
    placeholder: "Country /Region",
    required: true,
    type: "dropdown",
  },
  {
    field_name: "street_address",
    value: "",
    placeholder: "Street Address",
    required: true,
    type: "input",
  },
  {
    field_name: "town_city",
    value: "",
    placeholder: "Town / City",
    required: true,
    type: "input",
  },
  {
    field_name: "post_code",
    value: "",
    placeholder: "Post Code",
    required: true,
    type: "input",
  },
  {
    field_name: "phone",
    value: "",
    placeholder: "Phone",
    required: true,
    type: "input",
  },
  {
    field_name: "email_address",
    value: "",
    placeholder: "Email Address",
    required: true,
    type: "input",
  },
];

export const Shipping_detail = [
  {
    field_name: "shipment_first_name",
    value: "",
    placeholder: "First Name",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_last_name",
    value: "",
    placeholder: "Last Name",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_company_name",
    value: "",
    placeholder: "Company Name",
    required: false,
    type: "input",
  },
  {
    field_name: "shipment_country_region",
    value: "",
    placeholder: "Country /Region",
    required: true,
    type: "dropdown",
  },
  {
    field_name: "shipment_street_address",
    value: "",
    placeholder: "Street Address",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_town_city",
    value: "",
    placeholder: "Town / City",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_post_code",
    value: "",
    placeholder: "Post Code",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_phone",
    value: "",
    placeholder: "Phone",
    required: true,
    type: "input",
  },
  {
    field_name: "shipment_email_address",
    value: "",
    placeholder: "Email Address",
    required: true,
    type: "input",
  },
];
