import { Image } from "components/Images/Image";
import { Text } from "components/typography/Typography";
import { CategoryCardData, ContactSectionData } from "data/category";
import React from "react";
const ContactSection = () => {
  return (
    <div className="container">
      <div className="row">
        {ContactSectionData?.map((item,index) => (
          <div className="col-md-4 d-flex flex-column align-items-center px-5" key={index}>
            <Image src={item?.image} width={"60px"} height={"60px"} />
            <Text
              text={item?.title}
              className={"mb-2 contact-title-font mt-3"}
            />
            <Text
              text={item?.content}
              textAlign={"center"}
              className={"mb-0 contact-content-section"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactSection;
