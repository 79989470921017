import { Icon } from "components/Images/Image";
import React from "react";

const ButtonWithIcon = ({ onClick, title, className,icon }) => {
  return (
    <div>
      <button className={className} onClick={onClick}>
        <p className="mb-0">{title}</p>
        <Icon className={icon} margin={'0px 8px'}/>
        
      </button>
    </div>
  );
};

export default ButtonWithIcon;
