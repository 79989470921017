import { useContext } from "react";
import { useLocation } from "react-router-dom";


export const useGetParamsData = () => {
  const { pathname, key, state } = useLocation();


  return { pathname, key, state };
};

// export const useDataFlow = (contextValue) => {
//   const data = useContext(contextValue);
//   return  data ;
// };
