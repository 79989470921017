import SliderImageOne from "assets/images/Banner.png";
import SliderImageTwo from "assets/images/AdobeStock_267281715.jpg";
import SliderImageThree from "assets/images/gruppo-essenze.jpg";
import SliderImageFour from "assets/images/mamma-bimbo-bucato.jpg";

export const SlidersImage = [
  SliderImageOne,
  SliderImageTwo,
  SliderImageThree,
  SliderImageFour,
];

