import { createSlice } from "@reduxjs/toolkit";

const CategoryListing = createSlice({
  name: "productCategories",
  initialState: [],
  reducers: {
    setProductCategory: (state, action) => {
     return action.payload
    },
  },
});

export const { setProductCategory } = CategoryListing.actions;
export default CategoryListing.reducer;
