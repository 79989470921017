import Img004 from 'assets/images/004.png';
export const WhoWeArehtml = `<div>

<h1>Who we are</h1>
            <p><strong>AMARASICO</strong>  was born from the desire to find in the home the scents that evoke our dearest memories: in fact, we all know the evocative power of <strong>an essence, capable of taking you back to ancient places, lived moments or loved ones.</strong></p>
            <p>Aware of this power, we have decided to perfume our home so that what we live today can become a memory tomorrow.</p>
            <p>In this project <strong>AMARASICO</strong> has put  <strong>love and dedication</strong>  in the search for a line of fragrances to intensify the scent of the laundry, to perfume the environments that surround us and to take care of our skin paying attention to even the little ones, with <strong>a hypoallergenic line </strong> with  <strong>fragrances pleasant, sweet and fresh.</strong> The same care and attention has also been placed in every article present in our e-commerce in order to offer a quality product.</p>
            <p>In addition to the purely olfactory line, AMARASICO has dedicated itself to the search for a <strong>totally BIO</strong> body line  for the utmost respect for our skin and the environment. In fact, our lines are dermatologically tested, without parabens, without petrolatum, with  <strong>ICEA Certificate (Eco Bio Cosmetics Certificate)</strong> and the line dedicated to children uses 100% natural origin allergen-free fragrances.</p>
            <img class="Img004" src=${Img004}/>
            <h2 style="padding: 20px 0px 20px 0px; text-align: center;">Well all that remains is to say... LET’S PERFUME LIFE!</h2>
            <p>Do you want to become a <strong>DISTRIBUTOR</strong> of <strong>AMARASICO</strong> products ? Contact our offices and our staff will be happy to meet you and show you the qualities and characteristics of Amarasico products! Do not hesitate .. write to  amarasico@thsplus.co.uk</p>

 </div>`;