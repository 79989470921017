import { Image } from "components/Images/Image";
import { Text } from "components/typography/Typography";
import React from "react";
import { Link } from "react-router-dom";

export const CardwithText = ({
  CardTextClassName,
  CardTitle,
  fontFamily,
  fontSize,
  color,
  CardImageClassName,
  CardImage,
  CardImageHeight,
  CardImageWidth,
  onClick
}) => {
  return (
    <div className="col-md-6 px-5" onClick={onClick}>
      <Text
        className={CardTextClassName}
        text={CardTitle}
        fontFamily={fontFamily}
        fontSize={fontSize}
        color={color}
      />
      <Image
        className={CardImageClassName}
        src={CardImage}
        height={CardImageHeight}
        width={CardImageWidth}
      />
    </div>
  );
};

export const ProductCard = ({
  src,
  ImageHeight,
  ImageWidth,
  ProductName,
  PriceTextClass,
  PrdouctTextClass,
  PriceText,
  onClick,
  objectFit
}) => {
  return (
    <div className="col-md-2 cursor-pointer my-5" onClick={onClick} >
      <Image src={src} height={ImageHeight} width={ImageWidth} objectFit={objectFit}/>
      <Text text={ProductName} className={PrdouctTextClass} />
      <Text text={PriceText} className={PriceTextClass} />
    </div>
  );
};
