import AppLogo from "assets/images/logo-1651573269-removebg-preview.png";
import KidsImage from "assets/images/002.png";
import GiftImage from "assets/images/003.jpg";


export const Images = {
  AppLogo: AppLogo,
  KidsImage: KidsImage,
  GiftImage: GiftImage,




};
