import { Icon } from "components/Images/Image";
import React, { useState } from "react";
import { ThemeColor } from "utils/themeColor";

const Rating_Stars = ({
  disabled,
  ratingFiller,
  onHover,
  onMouseLeave,
  StarsFiller,
}) => {
  return (
    <React.Fragment>
      {Array.from({ length: 5 }).map((_, index) => (
        <Icon
          disabled={disabled}
          onHover={() => !disabled && onHover(index)}
          cursor={"pointer"}
          key={index}
          className={"bi bi-star"}
          fill={StarsFiller?.includes(index) || ratingFiller}
          color={ThemeColor.darkBlue}
          onMouseLeave={() => !disabled && onMouseLeave(index)}
        />
      ))}
    </React.Fragment>
  );
};

export default Rating_Stars;
