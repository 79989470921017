import ButtonWithTitle from "components/Buttons/ButtonWithTitle";
import { Text } from "components/typography/Typography";
import React from "react";
import { font_family } from "utils/fontFamily";

const ViewCartHeaderMessage = ({ title, isShowToModal,onClick }) => {
  return (
    <div>
      {isShowToModal && (
        <div className="bg-brown d-flex align-items-center justify-content-between">
          <Text
            text={title}
            fontSize={20}
            fontFamily={font_family?.textKarla}
            className={"mb-0"}
          />
          <ButtonWithTitle onClick={onClick} className={"view-cart"} title={"View Cart"} />
        </div>
      )}
    </div>
  );
};

export default ViewCartHeaderMessage;
