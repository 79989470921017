import { Text } from "components/typography/Typography";
import React from "react";
import { font_family } from "utils/fontFamily";
const InputField = ({
  fieldName,
  placeholder,
  ev,
  onChange,
  errorText,
  isError,
  value,
  type,
  checked,
  margin

}) => {
  return (
    <div className="row">
      <div className="col-md-12 " style={{ margin:margin }}>
        <label >{fieldName}</label> <br />
        <input
          onChange={(val) => onChange(val)}
          className="justify-content-center"
          type={type}
          id="firstName"
          placeholder={placeholder}
          required
          value={value}
        checked={checked}
          
        ></input>
        {isError && (
          <Text
            text={errorText}
            fontSize={11}
            fontFamily={font_family?.textEdo}
            color={"red"}
          />
        )}
      </div>
    </div>
  );
};

export default InputField;
