import ButtonWithTitle from "components/Buttons/ButtonWithTitle";
import { Icon } from "components/Images/Image";
import Inputwithtitle from "components/Inputs/InputwithIcons";
import { Text } from "components/typography/Typography";
import React, { useState } from "react";
import { ThemeColor } from "utils/themeColor";
import Rating_Stars from "./Rating";

const Reviews = () => {
  const [reviewHandler, setReviewHandler] = useState({
    backgroundColorFill: false,
    review: "",
    StarsToFill: [],
  });

  const onEnterReview = (value) => {
    setReviewHandler({ ...reviewHandler, review: value });
  };

  const onHover = (index) => {
    console.log(index);
    const starsArray = Array.from({ length: index + 1 }, (_, i) => i);
    setReviewHandler({
      ...reviewHandler,
      backgroundColorFill: true,
      StarsToFill: starsArray,
    });
  };
  const onMouseLeave = () => {
    setReviewHandler({
      ...reviewHandler,
      backgroundColorFill: false,
      StarsToFill: [],
    });
  };

  return (
    <div>
      <Text text={"Your rating*"} />
      <Rating_Stars
        StarsFiller={reviewHandler?.StarsToFill}
        disabled={false}
        onHover={onHover}
        onMouseLeave={onMouseLeave}
      />
      <Inputwithtitle
        placeholder={"Your review*"}
        containerClass={"review_input"}
        onChange={(val) => onEnterReview(val)}
      />
      <ButtonWithTitle title={"Submit"} className={"review-submit"} />
    </div>
  );
};

export default Reviews;
