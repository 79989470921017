import Header from 'components/Navbar';
import { WhoWeArehtml } from 'data/WhoWeAre';
import React from 'react'

export default function WhoWeAre() {
  return (
    <React.Fragment>
      <Header/>
    <div className="section" style={{ padding:"30px 0px 30px 0px" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 ">
            <div dangerouslySetInnerHTML={{ __html: WhoWeArehtml }}></div>
            
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>

  )
}
