import axios from "axios";
import { base_url, security_token } from "env/env";
import { api_constant } from "./apiConstant";

export const Send_Email = async (data) => {
  const response = {
    url: `${base_url}api/send-email`,
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.request(response);
};

export const Send_Form_Data = async (data) => {
  const response = {
    url: `https://script.google.com/macros/s/AKfycbxXPIWAhjnmnCMfg8YajbwTO8ly7BBqXxTokFVzY0dur-Dpsl01Y8QKx1zzerzLHFKi/exec`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  console.log(Array.from(data));
  return await axios?.request(response);
};

export const ProductListing = async () => {
  const response = {
    url: `${base_url}${api_constant?.productListing}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${security_token}`,
    },
  };

  return await axios?.request(response);
};

export const CategoryListing = async () => {
  const response = {
    url: `${base_url}${api_constant?.catogory}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${security_token}`,
    },
  };

  return await axios?.request(response);
};



