import React from "react";

export const Image = ({ width, height, src, className, objectFit }) => {
  return (
    <div className={className} style={{ width: width, height: height }}>
      <img
        style={{ width: "100%", height: "100%", objectFit: objectFit }}
        src={src}
        alt=""
      />
    </div>
  );
};

export const Icon = ({
  className,
  fontSize,
  color,
  fill,
  margin,
  onHover,
  onClick,
  onMouseLeave,
  cursor

}) => {
  return (
    <span
    
      style={{ color: color, fontSize: fontSize, margin,cursor: cursor}}
      onMouseEnter={onHover}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
    >
      <i className={`${fill ? className + "-" + "fill" : className}`}></i>
    </span>
  );
};
