import React, { useState } from "react";
import { CartDataContext } from "./Context";

const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState([]);
  const [themeColor, setThemeColor] = useState(false);
  const Values_Setter = {cartData, setCartData, themeColor, setThemeColor};
  return (
    <CartDataContext.Provider value={Values_Setter}>
      {children}
    </CartDataContext.Provider>
  );
};

export default CartProvider;
