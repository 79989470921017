import { useNavigate } from "react-router-dom";

export const navigateTo = (route,item,navigate) => {

  navigate(route, {state:{item}});
};


export const validateEmail=(string)=>{
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(string);
}