import InputField from "components/Inputs/InputField";
import Header from "components/Navbar";
import React from "react";

const MyAccount = () => {
  return (
    <div>
      <Header />
      <div className="section checkout-sec myaccount">
        <div className="container checkout-container ">
         
            {/* <div className="row">
              <div className="col-md-12">
                If you have shopped with us before, please enter your details
                below. If you are a new customer, please proceed to the Billing
                section.
              </div>
            </div> */}
            <div className="row" style={{ padding: "10px 20px" }}>
              <div className="col-md-6 ">
                <div className="row" style={{ padding: " 0px 10px" }}>
                  <div className="col-md-12 Dropdown-sec">
                    <h2>Login</h2>
                    <InputField fieldName={"Email"} placeholder={"Email"} />

                    <InputField
                      fieldName={"Password"}
                      placeholder={"Password"}
                      type={"password"}
                    />
                    <button type="submit">Login</button>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row" style={{ padding: " 0px 10px" }}>
                  <div className="col-md-12  Dropdown-sec" >
                    <h2>Register</h2>
                    <InputField fieldName={"Email"} placeholder={"Email"} />

                    <InputField
                      fieldName={"Password"}
                      placeholder={"Password"}
                      type={"password"}
                    />
                    <button type="submit">Register</button>
                  </div>
                </div>
              </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
