import React from "react";

import { Qualityhtml } from "data/quality";
import Header from "components/Navbar";
export default function quality() {
  return (
    <React.Fragment>    
      
      <Header/>
      <div className="container">
      <div className="section">
      
        <div className="row align-items-center">
          <div className="col-12 ">
            <div dangerouslySetInnerHTML={{ __html: Qualityhtml }}></div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>

  );
}
