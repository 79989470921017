import React from "react";

const MapView = () => {
  return (
    <div className="container my-3">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4810.695878520532!2d-1.480467!3d52.924165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879f13dda07b987%3A0x1ddb2d50ec9c2ef0!2sSadler%20Bridge%20Studios%2C%20Bold%20Ln%2C%20Derby%20DE1%203NT%2C%20UK!5e0!3m2!1sen!2sus!4v1716560476497!5m2!1sen!2sus"
      width="100%"
      height="350"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  );
};

export default MapView;
