import ButtonWithIcon from "components/Buttons/ButtonWithIcon";
import ButtonWithTitle from "components/Buttons/ButtonWithTitle";
import SimpleButton from "components/Buttons/SimpleButton";
import { Image } from "components/Images/Image";
import ReviewSectionSlider from "components/Swipers/ReviewSectionSlider";
import { Text } from "components/typography/Typography";
import Rating_Stars from "pages/ProductDetails/Rating";
import React from "react";
import { font_family } from "utils/fontFamily";
const ReviewSection = () => {
  return (
    <div className="row my-5">
      <div className="col-md-3">
        <div className="d-flex">
          <div>
            <Image
              src={
                "https://lh3.googleusercontent.com/places/AM5lPC-wwnAZJquxvzMhWcPg7ln19fyH0AC3zUk1dPozuRv6XKjUuPzS36hfRZLHxTH34GxkHka0kqFkcMvZgbggf1YFubHDM4zbvw=s1600-w300-h300"
              }
              objectFit={"cover"}
              className={"border_radius_profile"}
            />
          </div>
          <div className="px-4">
            <Text
              text={"Amarasico di Magli Robert"}
              className={"mb-1"}
              fontFamily={font_family?.textKarla}
              fontweight={"500"}
            />
            <div className="d-flex">
              <Text
                text={"4.9"}
                className={"mx-2 mb-1"}
                fontFamily={font_family?.textKarla}
                fontweight={"500"}
              />
              <Rating_Stars StarsFiller={[1, 2, 3, 4, 5]} disabled={true} />
            </div>
            <Text
              text={"Based on 56 reviews"}
              className={"mb-1"}
              fontFamily={font_family?.textKarla}
              fontweight={"500"}
            />
            <Image
              src={
                "https://amarasico.co.uk/wp-content/plugins/widget-google-reviews/assets/img/powered_by_google_on_white.png"
              }
              width={"130px"}
            />
            <ButtonWithIcon
              title={"review us on"}
              className={"google_review_button"}
              icon={"bi bi-google"}
            />
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <ReviewSectionSlider />
      </div>
    </div>
  );
};

export default ReviewSection;
