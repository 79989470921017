import { Text } from "components/typography/Typography";
import { AdditionInfo, keysNotInclude } from "data/additionInfoTab";
import React, { useEffect, useRef, useState } from "react";
import Reviews from "./RevewForm";

const AdditionalInfo = ({ heading, description, item_heading }) => {
  const [addtionInfo, setAdditionInfo] = useState({
    currentIndex: 0,
    itemToShow: {},
    fade_in: null,
  });

  const OnPressTab = (item, index) => {
    setAdditionInfo((prevValues) => ({
      ...prevValues,
      currentIndex: index,
      itemToShow: item,
      fade_in: "animate__fadeIn",
    }));
  };
  const onPointerLeave = () => {
    setAdditionInfo({ ...addtionInfo, fade_in: "" });
  };

  return (
    <div className="my-5">
      <div className="d-flex align-items-center infotab-header ">
        {AdditionInfo.map((item, index) => (
          <Text
            key={index}
            onClick={() => OnPressTab(item, index)}
            onPointerLeave={onPointerLeave}
            text={
              item.buttonText == "Reviews"
                ? `${item.buttonText}(0)`
                : item.buttonText || description
            }
            className={`px-5 py-1 mb-0 ${
              addtionInfo?.currentIndex === index ? "active_button" : "inactive"
            }`}
          />
        ))}
      </div>
      <div className={`${addtionInfo?.fade_in} tab animate__animated`}>
        <h2 className={"heading_text py-3"}>{heading}</h2>
        {Object.keys(AdditionInfo[addtionInfo?.currentIndex]).map(
          (item, index) =>
            !keysNotInclude?.includes(item) ? (
              <Text
                spantagClass={"font-weight-bold"}
                key={index}
                span_tag={`${item.toLocaleUpperCase().replace("_", " ")}: `}
                text={AdditionInfo[addtionInfo.currentIndex][item]}
              />
            ) : null
        )}
        {/* <Text
          spantagClass={"font-weight-bold"}
          span_tag={item_heading}
          text={description}
        /> */}
        {AdditionInfo[addtionInfo?.currentIndex]?.heading === "Reviews" && (
          <React.Fragment>
            <Text text={"There are no reviews Yet"} />
            <Reviews />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
