import React from "react";
import { useMemo } from "react";

export const Text = ({
  text,
  fontFamily,
  fontSize,
  color,
  className,
  textAlign,
  span_tag,
  spantagClass,
  onClick,
  fontweight,
  onPointerLeave,
  textWrap,
}) => {





  return (
    <>
      <p
      onClick={onClick}
      onPointerLeave={onPointerLeave}
        className={className}
        style={{
          textAlign: textAlign,
          fontFamily: fontFamily,
          fontSize: fontSize,
          color: color,
          fontWeight:fontweight,
          textWrap:textWrap
        }}
      >
        <span className={spantagClass}>{span_tag}</span>
        {text}
      </p>
    </>
  );
};
