import "App.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckOut from "components/Checkout/CheckOut";
import Footer from "components/Footer/Footer.jsx";
import ScrollToTopOnNavigate from "components/ScrollToTopNavigate";
import MyAccount from "components/Sections/MyAccount";
import RouteNotFound from "pages/404";
import Home from "pages/Home";
import ProductDetail from "pages/ProductDetails";
import ProductDisplay from "pages/ProductDisplay";
import Quality from "pages/Quality.jsx";
import WhoWeAre from "pages/WhoWeAre.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CartProvider from "statemanagement/CartProvider";
const App = () => {
  return (
    <CartProvider>
      <div className="App">
        <BrowserRouter>
          <ScrollToTopOnNavigate />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="products" element={<ProductDisplay />}>
              <Route path=":category" element={<ProductDisplay />} />
              <Route path="product-detail/:id" element={<ProductDetail />} />
            </Route>
            <Route path="quality" element={<Quality />} />
            <Route path="who-we-are" element={<WhoWeAre />} />
            <Route path="checkout" element={<CheckOut />} />
            <Route path="my-account" element={<MyAccount />} />
            <Route path="*" element={<RouteNotFound />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </CartProvider>
  );
};

export default App;
