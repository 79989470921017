import CardImageOne from "assets/images/essenze-bucato.jpg";
import CardImageTwo from "assets/images/spray-tessuti.jpg";
import CardImageThree from "assets/images/card-profumate-1.jpg";
import CardImageFour from "assets/images/prodotti-bio.jpg";
import ContactImageOne from "assets/images/Spedizione-in-48-ore.png";
import ContactImageTwo from "assets/images/whatsapp-v1.png";
import ContactImageThree from "assets/images/Pagamenti-sicuri.png";

import AddImageOne from "assets/images/01.jpg";
import AddImageTwo from "assets/images/001.png";
import AddImageThree from "assets/images/04-2.jpg";
import AddImageFive from "assets/images/03.jpg";
import AddImageSix from "assets/images/02.jpg";
import AddImageSeven from "assets/images/05.jpg";
export const AmrasicoProducts = [
  "Laudary Essence",
  "Fabric SPRAY",
  "CARD PROFUMATE",
  "CANDALEE",
  "GIFT CARD"
];

export const CategoryCardData = [
  { title: "LAUNDRY ESSENCES", Card_IMG: CardImageOne },
  { title: "FABRIC SPRAY", Card_IMG: CardImageTwo },
  { title: "SCENTED CARD", Card_IMG: CardImageThree },
  { title: "BIO PRODUCTS", Card_IMG: CardImageFour },
];

export const ContactSectionData = [
  {
    title: "Shipping in 48 hours",
    content: "For payments by bank transfer, shipping upon receipt of payment",
    image: ContactImageOne,
  },
  {
    title: "Whatsapp assistance",
    content:
      "For any information do not hesitate to contact us on Whatsapp or by phone",
    image: ContactImageTwo,
  },
  {
    title: "Secure payments",
    content: "Choose the payment that is most convenient for you",
    image: ContactImageThree,
  },
  
];



export const InstagramProductData = [
  AddImageOne,
  AddImageTwo,
  AddImageThree,
  AddImageFive,
  AddImageSix,
  AddImageSeven,
];
