import { Image } from "components/Images/Image";
import { Text } from "components/typography/Typography";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDataFlow, useGetParamsData } from "utils/customHooks";
import "pages/ProductDetails/index.css";
import { ThemeColor } from "utils/themeColor";
import ButtonWithTitle from "components/Buttons/ButtonWithTitle";
import Inputwithtitle from "components/Inputs/InputwithIcons";
import { font_family } from "utils/fontFamily";
import AdditionalInfo from "./AddtionalDetail";
import { RelativeProducts } from "data/relativeProducts";
import { ProductCard } from "components/Cards/Card";
import { CartDataContext } from "statemanagement/Context";
import Header from "components/Navbar";
import Tooltip from "components/Tooltips/Tooltip";
import ViewCartHeaderMessage from "./ViewCartHeaderMessage";
import ViewCartModal from "components/Modals/ViewCartModal";
import { useSelector } from "react-redux";

const ProductDetail = () => {
  const [cartHandler, setCartHandler] = useState({
    productNumbers: 1,
    inStockEement: 15,
    isAnimate: false,
    attribut_price: "",
    isToolTipAnimate: false,
    ToolTipText: "Added To Cart",
    CartButtonTitle: "Add To Basket",
    isToolTipText: "Product Added To The Basket",
    isShowViewCartButton: false,
    isOpenCartModal: false,
    isErrorShow: false,
  });
  const { state } = useGetParamsData();
  const { item } = state || {};
  const animateSection = useRef(null);
  const { cartData, setCartData } = useContext(CartDataContext);
  const {data} = useSelector((state) => state.products);
  const Animate_ref = useRef(null);
  useEffect(() => {
    if (cartHandler?.isAnimate) {
      animateSection.current?.classList.add("display-add-show");
    } else {
      animateSection.current?.classList.remove("display-add-show");
      animateSection.current.classList.add("display-none");
    }
    if (cartHandler?.isToolTipAnimate) {
      Animate_ref.current?.classList.add("show-in", "animate__wobble");
      const animation_time = setTimeout(() => {
        Animate_ref.current?.classList.remove("show-in", "animate__wobble");
        setCartHandler((prevCartHandler) => ({
          ...prevCartHandler,
          isToolTipAnimate: false,
        }));
        clearTimeout(animation_time);
      }, 2000);
    }
  }, [cartHandler?.isAnimate, cartHandler?.isToolTipAnimate]);

  const OnCartValueHandle = (value) => {
    if (
      value >= 1 &&
      Number(value) <= cartHandler?.inStockEement &&
      cartHandler?.attribut_price !== ""
    ) {
      setCartHandler({
        ...cartHandler,
        productNumbers: Number(value),
      });
    }
  };

  const OnSelectedAttribute = (attribute) => {
    // const calculateAttributePrice = item?.price.split("-");
    // const calculateAttributePrice = item?.price.split("-");

    // const finalCalculation =
    //   attribute === `${item?.size}${item?.unit}`
    //     ? calculateAttributePrice[0]
    //     : calculateAttributePrice[1];

    setCartHandler({
      ...cartHandler,
      isAnimate: true,
      attribut_price: item?.min_price,
    });
  };

  const OnClearAttributes = () => {
    setCartHandler({
      ...cartHandler,
      isAnimate: false,
      productNumbers: 1,
      attribut_price: "",
      inStockEement: 15,
    });
  };

  const OnAddBasket = (item) => {
    if (cartHandler?.attribut_price !== "") {
      setCartHandler((prevValues) => ({
        ...prevValues,
        CartButtonTitle: "Update Cart",
        isToolTipAnimate: true,
        isShowViewCartButton: true,
        isToolTipText: !cartData?.some((cart) => cart.id == item?.id)
          ? "Product Add To Cart"
          : "Product Updated",
      }));
      setCartData((prevValues) => [
        ...prevValues?.filter((cart) => cart.id !== item?.id),
        {
          ...item,
          productPrice: Number(cartHandler?.attribut_price.replace("£", " ")),
          productQuantity: cartHandler?.productNumbers,
        },
      ]);
    } else {
      setCartHandler({
        ...cartHandler,
        isToolTipAnimate: true,
        isToolTipText: "Please Select Variants",
        isErrorShow: true,
      });
    }
  };

  const OpenCartModal = () => {
    setCartHandler((prevCartValues) => ({
      ...prevCartValues,
      isOpenCartModal: true,
    }));
  };

  return (
    <React.Fragment>
      <Header OnOpenCart={OpenCartModal} />

      <div className="container my-5">
        <div className="row">
          <ViewCartHeaderMessage
            onClick={OpenCartModal}
            title={item?.title}
            isShowToModal={cartHandler?.isShowViewCartButton}
          />
          <div className="col-md-5 px-4 position-relative">
            <Image
              src={`${item?.image_path}/${item?.image}`}
              objectFit={"contain"}
            />
          </div>

          <div className="col-md-7 px-4">
            <h2 className={"heading_text"}>{item?.title}</h2>
            <Text className={"text-plain"} text={item?.description} />
            <Text
              spantagClass={"text-plain-bold"}
              span_tag={"Reference:" + " "}
              className={"text-plain"}
              text={item?.refrence}
            />
            <Text
              spantagClass={"text-plain-bold"}
              span_tag={"SKU:" + " "}
              className={"text-plain"}
              text={item?.sku}
            />
            <Text
              spantagClass={"text-plain-bold text-black"}
              span_tag={"Price:" + " "}
              text={`£${item?.min_price} - £${item?.max_price}`}
              color={ThemeColor?.maroon}
            />
            <Text
              className={"text-plain-bold p-0"}
              text={"Choose bottle format"}
            />
            <div className="d-flex">
              <Text
                className={`attributes ${
                  cartHandler?.isErrorShow ? "border-blue" : ""
                }`}
                text={`${item?.size}${item?.unit}`}
                onClick={() =>
                  OnSelectedAttribute(`${item?.size}${item?.unit}`)
                }
              />
              {/* <Text
                className={`attributes mx-4 ${
                  cartHandler?.isErrorShow ? "border-blue" : ""
                }`}
                text={item?.attributes[1]}
                onClick={() => OnSelectedAttribute("500ml")}
              /> */}
            </div>

            <div className="display-none" ref={animateSection}>
              <Text
                text={cartHandler?.attribut_price}
                color={ThemeColor?.darkBlue}
                fontFamily={font_family?.textKarla}
              />
              <Text
                text={`${
                  item?.quantity - cartHandler?.productNumbers
                } In Stock`}
                color={ThemeColor?.maroon}
                fontFamily={font_family?.textKarla}
              />
              <Text
                onClick={OnClearAttributes}
                className={"clear-text"}
                text={"clear"}
                color={ThemeColor?.darkBlue}
                fontFamily={font_family?.textKarla}
              />
            </div>

            <div className="d-flex position-relative">
              <Inputwithtitle
                onChange={(val) => OnCartValueHandle(val)}
                type={"number"}
                value={cartHandler?.productNumbers}
                containerClass={"input_container"}
              />
              <ButtonWithTitle
                title={cartHandler?.CartButtonTitle}
                className={"add_basket mx-4"}
                onClick={() => OnAddBasket(item)}
              />
              <Tooltip text={cartHandler?.isToolTipText} getRef={Animate_ref} />
            </div>
          </div>
        </div>
        <AdditionalInfo
          description={item?.description}
          heading={item?.title}
          item_heading={"Description"}
        />
        <div className="row">
          <h2 className={"border-0 heading_text mb-2"}>Related Products</h2>
          {data.map((item, index) => (
            <ProductCard
              key={index}
              PriceText={`£${item?.min_price} - £${item?.max_price}`}
              src={`${item?.image_path}/${item?.image}`}
              ProductName={item?.title}
              PrdouctTextClass={"product-card-title mb-1"}
              PriceTextClass={"price-text-style"}
            />
          ))}
        </div>

        <ViewCartModal
          isOpenModal={cartHandler?.isOpenCartModal}
          onClose={() => {
            setCartHandler({ ...cartHandler, isOpenCartModal: false });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;
