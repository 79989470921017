import { ProductCard } from "components/Cards/Card";
import { image_url } from "env/env";
import React from "react";
import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import { navigateTo } from "utils/methods";
import NoDataFound from "assets/lottiefiles/Animation - 1721835246316.json";
const OurEssenceSection = ({
  sectionHeading,
  path,
  textAlign,
  margin,
  OurEssenceProduct,
}) => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NoDataFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="container">
        <h1
          className="essence_heading  mt-5 mb-3 w-100"
          style={{ textAlign: textAlign, margin: margin }}
        >
          {sectionHeading}
        </h1>
        <div className="row">
          {OurEssenceProduct?.map((item, index) => (
            <ProductCard
              key={index}
              PriceText={item?.price}
              ImageHeight={"100%"}
              ImageWidth={"100%"}
              objectFit={"cover"}
              src={image_url + "/products/" + item?.image}
              ProductName={item?.title}
              PrdouctTextClass={"product-card-title mb-1"}
              PriceTextClass={"price-text-style"}
              onClick={() =>
                navigateTo(`${path}/${item?.title}`, item, navigate)
              }
            />
          ))}
          {OurEssenceProduct?.length == 0 && (
            <Lottie options={defaultOptions} height={250} width={250} />
          )}
        </div>
      </div>
    </>
  );
};

export default OurEssenceSection;
