import Bimbo from "assets/images/bimbo.jpg";
export const Qualityhtml = `  
 <div>
      
              <h1>Essences for perfumed laundry, discover our fragrances</h1>
              <p>The line of essences for laundry proposed by AMARASICO is totally MADE IN ITALY , and was born from the union of natural oils and synthetic oils, to perfume our garments for days. </p>
              <p>Without allergens, without toxic and carcinogenic substances, studied and dedicated also to children!</p>
              <p>To ensure high standards, carcinogenic and toxic substances have been eliminated so as to make the line at the top both in terms of quality and persistence.</p>
              <p>The use of fragrances for laundry is simple: just add the chosen quantity (based on the laundry load) in the washing machine compartment dedicated to the softener and / or the last rinse and you will immediately see that the laundry is in phase drying which, once placed in the wardrobe, will give your home a delicate and pleasant scent that will envelop you like a warm and reassuring embrace. </p>
              <p>The essences, obtained from a close collaboration with expert perfumers, will satisfy all people, even the most demanding and sophisticated ones as the bouquet ranges from sweet to delicate notes, from soft to woody, from floral to decisive.</p>
              <p>Our line is divided into:</p>
              <ul>
                <li><strong>PERSISTENT WITHOUT ALLERGENS;</strong> line designed and dedicated to children, to the most delicate people in full compliance with EC regulation n ° 1223/2009;</li>
                <li><strong>PERSISTENT CLASSIC</strong> where every fragrance, every note enhances the personal pleasure from the softest and most delicate to the most tenacious and decisive!</li>
              </ul>
              <h2 style={{ textAlign:"center" }}>OUR STRENGTHS</h2>
              <ul style={{ color:"#E02B20" }}>
                <li>100% Made in Italy</li>
                <li>The dispenser against waste, for the respect of the environment and a correct use of the essence / fragrance.</li>
                <li>BIO certified creams and detergents line with raw materials from dermatologically certified organic farming Tested at the University of Ferrara – no parabens / no synthetic dyes / no petrolatum / no SLES</li>
              </ul>
              <img src=${Bimbo} style="height:100%;width:100%;objectFit:contain"/>
              <h5><br/>CREAM LINE WITH SNAIL SLIME – BIO</h5>
              <p>The BIO line with Snail Slime is formulated with 10% pure snail slime and its natural characteristics and properties – such as collagen, allantoin, elastin, glycolic acid, and Vitamin ACE – will maintain the young and healthy skin for longer in a natural way.</p>
              <p>The properties of snail slime are:</p>
              <ul>
                <li><strong>Antioxidant:</strong> Rich in vitamins C and E capable of fighting free radicals and reducing and attenuating inflammation, and any redness of the skin.</li>
                <li><strong>Protective:</strong>  Thanks to collagen and elastin, daily use regenerates the tissues, protecting and fighting the signs of aging.</li>
                <li><strong>Moisturizing:</strong> The slime is rich in proteins that increase tissue oxygenation, giving the skin the right hydration.</li>
                <li><strong>Regenerating:</strong> The natural composition and the presence of allantoin helps fight the aging process of the skin, reducing spots and scars.</li>
                <li><strong>Illuminating:</strong> The glycolic acid contained in it prevents the formation of impurities, counteracting acne and keeping the skin visibly healthier.</li>
                <li><strong>Elasticizing:</strong> Gives tone to the tissues, carrying out a filling action that prevents the appearance of wrinkles.</li>
                <li><strong>Soothing:</strong> Thanks to allantoin combined with mucopolysaccharides and proteins it fights and prevents redness due to the sun or cold.</li>
              </ul>

          
      </div>
   `;
