import Header from "components/Navbar";
import OurEssenceSection from "components/Sections/OurEssenceSection";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
const ProductDisplay = () => {
  const { category } = useParams();
  const { data } = useSelector((state) => state.products);

  return (
    <div>
      {category ? (
        <React.Fragment>
          <Header />
          <OurEssenceSection
            textAlign={"left"}
            OurEssenceProduct={data?.filter(
              (item) =>
                item?.cat_name ==
                category?.replace("_", " ").toLocaleUpperCase()
            )}
            sectionHeading={category}
            path={"product-detail"}
          />
        </React.Fragment>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default ProductDisplay;
