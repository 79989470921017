import "components/Swipers/swiper.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const AutoSliderSwiper = ({ Data,autoplayDelay,autoplay,className,slidesPerView }) => {
  return (
    <>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        slidesPerView={slidesPerView}
        autoplay={{
          delay: autoplayDelay,
          autoplay:autoplay,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className={className}
      >
        {Data?.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
export default AutoSliderSwiper;
