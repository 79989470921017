import { Text } from "components/typography/Typography";
import React, { useEffect, useRef, useState } from "react";
import { font_family } from "utils/fontFamily";
import { ThemeColor } from "utils/themeColor";
const Tooltip = ({ getRef, text }) => {
  return (
    <React.Fragment>
      <div className="tooltip-show animate__animated" ref={getRef}>
        <Text
          className={"mb-0"}
          text={text}
          color={ThemeColor?.white}
          fontSize={12}
          fontFamily={font_family?.textKarla}
        />
      </div>
    </React.Fragment>
  );
};

export default Tooltip;
